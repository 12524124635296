// React
import React from "react";
import PropTypes from "prop-types";

// Material ui
import {makeStyles} from "@material-ui/core/styles";
import {
  Typography,
  Button,
  Container,
  Paper,
  Slide,
} from "@material-ui/core";
import {ArrowForward} from "@material-ui/icons";
import {GatsbyImage} from "gatsby-plugin-image";
import {countryToFlag} from "../../utils/formatting";

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: theme.spacing(6),
    // marginBottom: theme.spacing(6),
    // background: "linear-gradient(0deg, rgba(249,249,249,1) 0%, rgba(255,255,255,1) 30%)",
  },
  mainHeaderText: {
    textAlign: "center",
    margin: "auto",
    fontSize: "2.7rem",
    fontWeight: 700,
    maxWidth: 630,
  },
  subHeaderText: {
    maxWidth: 660,
    textAlign: "center",
    marginLeft: "auto",
    marginRight: "auto",
    opacity: 0.85,
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(3),
  },
  buttonContainer: {
    display: "flex",
    marginLeft: "auto",
    marginRight: "auto",
    justifyContent: "center",
    marginBottom: theme.spacing(3),
  },
  mainCtaButton: {
    minWidth: 180,
    height: 50,
    fontSize: 18,
    // display: "block",
  },
  smallPrint: {
    marginTop: theme.spacing(2),
    textAlign: "center",
    opacity: 0.6,
  },
  backgroundImagePattern: {
    // opacity: 0.3,

    // backgroundImage: 'radial-gradient(circle at center center, rgba(61, 90, 254, 0.04), #ffffff), repeating-radial-gradient(circle at center center, rgba(61, 90, 254, 0.04), rgba(61, 90, 254, 0.04), 38px, transparent 76px, transparent 38px)',
    // backgroundBlendMode: 'multiply',


    //'radial-gradient(#3D5AFE 1.8px, transparent 1.8px), radial-gradient(#3D5AFE 1.8px, #ffffff 1.8px)',
    // backgroundSize: '46px 46px',
    // backgroundPosition: '0 0, 23px 23px',
    opacity: 0.7,
    backgroundImage: 'radial-gradient(rgba(61, 90, 254, 0.2) 1.9px, #ffffff 1.9px)',
    backgroundSize: '38px 38px',


    // background: 'linear-gradient(135deg, #3D5AFE55 25%, transparent 25%) -38px 0/ 76px 76px, linear-gradient(225deg, #3D5AFE 25%, transparent 25%) -38px 0/ 76px 76px, linear-gradient(315deg, #3D5AFE55 25%, transparent 25%) 0px 0/ 76px 76px, linear-gradient(45deg, #3D5AFE 25%, #ffffff 25%) 0px 0/ 76px 76px'
  },
  backgroundImageOpacity: {
    // opacity: 0.7,
    // paddingTop: theme.spacing(10),
    [theme.breakpoints.down('sm')]: {
      paddingTop: theme.spacing(6),
      paddingBottom: theme.spacing(6),
      paddingRight: theme.spacing(3),
      paddingLeft: theme.spacing(3),
    },
    [theme.breakpoints.up('md')]: {
      padding: theme.spacing(10),
    },
    backgroundImage:  'linear-gradient(0deg, rgba(61, 90, 254, 0.04), rgba(255, 255, 255, 1))',
    zIndex: -1,
  },
  imagePaper: {
    zIndex: 1,
    maxWidth: 1000,
    marginLeft: "auto",
    marginRight: "auto",
    boxShadow: "0 20px 20px 0 rgba(61, 90, 254, .1)",
  },
}));

export default function HomeTopHero(props) {
  const {id, image, handleButtonClick} = props;
  const classes = useStyles();
  return (
    <div className={classes.root} id={id || "hero"}>
      <Container>

        <Typography
          className={classes.mainHeaderText}
          variant="h4"
          component="h1"
          align="left"
          gutterBottom
        >
          A better way to hire and pay a remote freelance team
          {/* Hire a flexible marketing team. Pay only for the right skills, at the right time.  */}
        </Typography>
        <Typography
          className={classes.subHeaderText}
          variant="h6"
          align="left"
        >
          {`Move faster with one flexible team contract. Work with a team lead to assign the right tasks, to the right members, at the right rate. ${countryToFlag('IN')}`}


          {/* Move faster with one flexible team contract. Work with a vetted team lead, delegating the right tasks, to the right member, at the right rate. */}

          {/* Ditch static one-on-one contracts. Use one single flexible team contract. Work with a vetted team lead who delegates the right tasks, to the right member, at the right rate. */}
          {/* One single team contract to manage everything. No monthly retainer fees. No long-term commitments. Just a flexible team of freelancers, paid per hour. */}

          {/* One single flexible team contract. One payment method. One billing overview. Multiple freelancers, working from anywhere, growing your business. */}
          {/* Stop hiring jack-of-all-trades. Hire a Maxer team instead and rest assured the right tasks get done by the right freelancer.  */}
        </Typography>
        <div className={classes.buttonContainer}>
          <Button
            id="submit-btn"
            variant="contained"
            color="primary"
            className={classes.mainCtaButton}
            onClick={handleButtonClick}
            style={{marginRight: 16, fontWeight: 700 }}
            size="large"
            endIcon={<ArrowForward/>}
          >
            Find your team
          </Button>
          {/*<Button*/}
          {/*  id="submit-btn"*/}
          {/*  variant="outlined"*/}
          {/*  color="primary"*/}
          {/*  className={classes.mainCtaButton}*/}
          {/*  size="large"*/}
          {/*>*/}
          {/*  View pricing*/}
          {/*</Button>*/}
        </div>
        {/*<Typography className={classes.smallPrint}>Generous free tier. Zero data-engineering required.</Typography>*/}
        {/*<Img className={classes.image} fluid={imgSizes} alt=""/>*/}
      </Container>
      <div className={classes.backgroundImagePattern}>
        <div className={classes.backgroundImageOpacity}>
          <Slide direction="up" in={true} timeout={2000} mountOnEnter unmountOnExit>
            <Paper className={classes.imagePaper}>
              <GatsbyImage
                image={image}
                alt=""
                fadeIn={true}
              />
            </Paper>
          </Slide>
        </div>
      </div>
    </div>
  );
}

HomeTopHero.propTypes = {
  id: PropTypes.string,
  image: PropTypes.object.isRequired,
  // primaryText: PropTypes.string.isRequired,
  // secondaryText: PropTypes.string.isRequired,
  showImageLeft: PropTypes.bool,
};