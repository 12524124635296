// React
import React from "react";
import {graphql, navigate, useStaticQuery} from "gatsby";

// Components
import HomeTopHero from "../components/HomeTopHero"; //HomeBottomHero
import Seo from "../components/Seo";
import {PublicNavbar} from "../components/Navbars";
import Footer from "../components/Footer";
// import BenefitBlock from "../components/BenefitBlock";

// Material UI
import {Box} from "@material-ui/core";

// Constants
import * as routes from "../constants/routes";

// Other libs
// import { GatsbyImage, getImage } from "gatsby-plugin-image";

// function getSolutionItemImage(images, contentfulId) {
//   const {edges} = images;
//   const filtered = edges.filter((e) => e.node.contentful_id === contentfulId);
//   if (filtered.length >= 1) {
//     return filtered[0].node.sizes;
//   }
// }

function useImages() {
  return useStaticQuery(
    graphql`
      query {
        solutionImages: allContentfulAsset(
          filter: {
            contentful_id: {
              in: [
                "5o3ltxrbItTvfmQyhToRAS"
                "6omq8JjKWxHpv13OTo6gUy"
                "4w7yOggwHBhICLkCtiQJQ0"
                "6QviHpkntuyvykUnOehzy"
              ]
            }
          }
        ) {
          edges {
            node {
              title
              id
              contentful_id
              gatsbyImageData(layout: FULL_WIDTH)
            }
          }
        }
        topHeroImage: contentfulAsset(
          contentful_id: { eq: "3vs2ijQuSvD8aiOGivY7Nz" }
        ) {
          gatsbyImageData(
            layout: FULL_WIDTH
          )
        }
      }
    `
  );
}

export default function Index() {
  const {topHeroImage} = useImages();
  // const authUser = React.useContext(AuthUserContext);
  //
  // React.useEffect(() => {
  //   console.log("authUser", authUser)
  // }, [authUser]);
  // const {solutionImages, topHeroImage, bottomHeroImage} = useImages();

  // const [showCallout, setShowCallout] = React.useState(true);
  // const theme = useTheme();

  // const handleGoToFreelancers = () => navigate(routes.freelancers);

  // const goalDefImg = getSolutionItemImage(
  //   solutionImages,
  //   "5o3ltxrbItTvfmQyhToRAS"
  // );
  // const flexContractImg = getSolutionItemImage(
  //   solutionImages,
  //   "4w7yOggwHBhICLkCtiQJQ0"
  // );
  // const sourceComparisonsImg = getSolutionItemImage(
  //   solutionImages,
  //   "6QviHpkntuyvykUnOehzy"
  // );
  // const graphImg = getSolutionItemImage(
  //   solutionImages,
  //   "6omq8JjKWxHpv13OTo6gUy"
  // );

  return (
    <Box>
      <Seo
        title="Maxer | On-demand performance marketing teams"
        description="Maxer offers the professionality and convenience of a world-class agency, at a freelancer price point. The secret: A dedicated project manager who makes sure you always hire the right freelancer, for the right task, at a fair rate."
        image="https://images.ctfassets.net/h6w739vsk2p5/by2Uog4TD9JsCgt9qQ2Yv/2b10041a29825c7d4174940fa63c8783/logo-with-text.svg"
        pathname="/"
      />
      <PublicNavbar/>
      <HomeTopHero
        id="top-hero"
        image={topHeroImage.gatsbyImageData}
        handleButtonClick={() => navigate(routes.signup)}
      />
      <Footer/>
    </Box>
  );
}

